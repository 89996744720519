import router from './router'
import store from './store'
import { Toast } from 'vant'
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import { pathMatcher } from '@/utils/index'
import { getLogo } from '@/api/user'

const whiteList = ['/softwareDownload', '/performance_record', '/rechargeTutorial', '/verifyFairness', '/commisRecord', '/inviteFriends', '/rechargeRecord', '/recharge', '/user', '/rules', '/orderRecord', '/detailView', '/home', '/login', '/registry', '/', '/index', '/category', '/detail/**', '/diamondGoodsList/**'] // 白名单
const telList = ['/groupBag', '/sendBag', '/peopleList', '/compositor', '/talkAdventure', '/wealthRankings']
router.beforeEach(async(to, from, next) => {
  // const arr = Object.keys(query) // 获取须带参数对象的所有key
  // for (let i = 0; i < arr.length; i++) {
  //   if (!to.query[arr[i]]) to.query[arr[i]] = query[arr[i]] // 没有带上的就赋值带上
  // }
  // 设置标题
  // document.title = to.meta.title || 'panda-mall'
  await getLogo().then(res => {
    document.title = res.logoname
    // var link = document.getElementsByTagName('link')[0]
    var link1 = document.createElement('link')
    // var link2 = document.getElementsByTagName('link')['apple-touch-icon']
    var meta = document.getElementsByTagName('meta')['apple-mobile-title']
    link1.id = 'longlogo'
    // link.href = res.logourl2
    link1.href = res.logourl
    // link2.href = res.logourl2
    meta.content = res.logoname
    var head = document.getElementsByTagName('head')[0]
    head.appendChild(link1)
  })
  // document.title = '人人娱乐'
  // const tokenValue = getToken()
  // console.log('token' + tokenValue)
  // const query = { suid: getToken() && tokenValue !== 'undefined' ? getToken() : store.getters.token ? store.getters.token : '' }
  // // if (to.query.type === 'logout') {
  // //   delete to.query.suid
  // //   const transitionTo = router.history.transitionTo // 临时存储底层方法
  // //   router.history.transitionTo = function(location, onComplete, onAbort) {
  // //     location = typeof location === 'object' ? { ...location, query: { ...location.query, ...query }} : { path: location, query }
  // //     transitionTo.call(router.history, location, onComplete, onAbort)
  // //   }
  // // }
  // if (to.query.suid) {
  //   await store.dispatch('user/resetToken', to.query.suid)
  //   // if (from.path !== to.path) {
  //   //   next()
  //   // }
  // } else if (query.suid && query.suid !== 'undefined') {
  //   // const transitionTo = router.history.transitionTo // 临时存储底层方法
  //   // router.history.transitionTo = function(location, onComplete, onAbort) {
  //   //   location = typeof location === 'object' ? { ...location, query: { ...location.query, ...query }} : { path: location, query }
  //   //   transitionTo.call(router.history, location, onComplete, onAbort)
  //   // }

  //   // await store.dispatch('user/getInfo')
  //   // next({ path: to.path, query: { ...to.query, ...query }})
  // }
  // if (!store.getters.userInfo.mons) {
  //   // await store.dispatch('user/getInfo') ??升级状态会有问题暂时关闭
  // }
  // if (!to.query.suid && !pathMatcher(telList, to.path)) {
  //   // next({ path: to.path, query: { ...to.query, ...query }})
  //   next()
  // } else {
  //   next()
  // }

  // 根据token判断用户是否登录
  const hasToken = getToken()

  if (hasToken) {
    await store.dispatch('user/resetToken', hasToken)
    if (to.path === '/login') {
      // 如果已经登录了，而去的又是login页就重定向到首页
      next({ path: '/' })
    } else {
      const hasUserInfo = store.getters.userInfo.ucode
      if (hasUserInfo) {
        next()
      } else {
        // 如果用户刷新了浏览器，那么需要重新请求基本信息塞到vuex中进行状态管理
        try {
          await store.dispatch('user/getInfo')
          next()
        } catch (error) {
          if (pathMatcher(whiteList, to.path)) {
            next()
          } else {
            next()
            // 清空token重新去登录
            // await store.dispatch('user/resetToken')
            // next(`/login?redirect=${encodeURIComponent(location.href)}`)
          }
        }
      }
    }
  } else {
    next()
    // if (pathMatcher(whiteList, to.path)) {
    //   next()
    // } else {
    //   next()// 该项目无注册登录
    //   // 反之则去登录页面
    //   // next(`/login?redirect=${encodeURIComponent(location.href)}`)
    // }
  }
})

router.afterEach(() => {
  Toast.clear()
})
