import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const routes = [
  {
    path: '*',
    // redirect: '/upgradeSystem'
    redirect: to => {
      if (localStorage.getItem('version') === 'old') {
        return '/oldhome'
      } else {
        return '/home'
      }
    } // localStorage.getItem('version') === 'old' ? '/home' :
  },
  // 首页
  {
    path: '/oldhome',
    name: 'Home',
    // 路由懒加载：https://router.vuejs.org/zh/guide/advanced/lazy-loading.html
    // webpackPreload：https://www.jianshu.com/p/bbdcfeee7fbc
    // component: () => import(/* webpackPreload: true */ '@/views/home'),
    component: () => import(/* webpackPreload: true */ '@/views/portal'),
    meta: {
      title: '首页',
      showTab: true
      // keepAlive: true
    }
  },
  // 新首页
  {
    path: '/home',
    name: 'NewHome',
    component: () => import('@/views/portal/newHome.vue'),
    meta: {
      title: '新首页',
      showTab: true
    }
  },
  // 菜单
  {
    path: '/menuList',
    name: 'MenuList',
    component: () => import('@/views/portal/menuList.vue'),
    meta: {
      title: '菜单'
    }
  },
  // 菜单
  {
    path: '/backRate',
    name: 'BackRate',
    component: () => import('@/views/portal/backRate.vue'),
    meta: {
      title: '佣金奖励比例'
    }
  },
  // 游戏iframe
  {
    path: '/iframeGame',
    name: 'iframeGame',
    component: () => import('@/views/portal/iframeGame.vue'),
    meta: {
      title: '游戏中'
    }
  },
  // 邀请好友
  {
    path: '/shareFriends',
    name: 'shareFriends',
    component: () => import('@/views/portal/shareFriends.vue'),
    meta: {
      title: '邀请好友'
    }
  },
  // 任务中心
  {
    path: '/taskCenter',
    name: 'taskCenter',
    component: () => import('@/views/taskCenter'),
    meta: {
      title: '任务中心'
    }
  },
  // 电子游戏列表
  {
    path: '/gameList',
    name: 'GameList',
    component: () => import('@/views/portal/gameList.vue'),
    meta: {
      title: '电子游戏'
    }
  },
  // 新电子游戏列表
  {
    path: '/newGameList',
    name: 'NewGameList',
    component: () => import('@/views/portal/newGameList.vue'),
    meta: {
      title: '电子游戏'
    }
  },
  // 私钥验证
  {
    path: '/verifyIndex',
    name: 'VerifyIndex',
    component: () => import('@/views/portal/verifyIndex.vue'),
    meta: {
      title: '私钥验证'
    }
  },
  // 期号查询
  {
    path: '/searchMoreInfo',
    name: 'SearchMoreInfo',
    component: () => import('@/views/portal/detailView/searchMoreInfo.vue'),
    meta: {
      title: '期号查询'
    }
  },
  // 真心话大冒险
  {
    path: '/talkAdventure',
    name: 'TalkAdventure',
    component: () => import('@/views/tel/talkAdventure.vue'),
    meta: {
      title: '真心话大冒险'
    }
  },
  // 群红包
  {
    path: '/groupBag',
    name: 'GroupBag',
    component: () => import('@/views/tel/groupBag.vue'),
    meta: {
      title: '发红包'
    }
  },
  // 群红包
  {
    path: '/hongbao',
    name: 'Hongbao',
    component: () => import('@/views/tel/hongbao.vue'),
    meta: {
      title: '发红包'
    }
  },
  // tel充值
  {
    path: '/telRecharge',
    name: 'TelRecharge',
    component: () => import('@/views/tel/telRecharge.vue'),
    meta: {
      title: '充值'
    }
  },
  // 联系人
  {
    path: '/peopleList',
    name: 'PeopleList',
    component: () => import('@/views/tel/peopleList.vue'),
    meta: {
      title: '指定领取人'
    }
  },
  // 发红包
  {
    path: '/sendBag',
    name: 'SendBag',
    component: () => import('@/views/tel/sendBag.vue'),
    meta: {
      title: '发红包（旧）'
    }
  },
  // 排行榜
  {
    path: '/compositor',
    name: 'Compositor',
    component: () => import('@/views/tel/compositor.vue'),
    meta: {
      title: '排行榜'
    }
  },
  // 财富排行榜
  {
    path: '/wealthRankings',
    name: 'WealthRankings',
    component: () => import('@/views/tel/wealthRankings.vue'),
    meta: {
      title: '排行榜'
    }
  },
  // 升级系统
  {
    path: '/upgradeSystem',
    name: 'UpgradeSystem',
    component: () => import('@/views/portal/upgradeSystem.vue'),
    meta: {
      title: '游戏页'
    }
  },
  {
    path: '/showPdf',
    name: 'ShowPdf',
    component: () => import('@/views/portal/inviteFriends/showPdf.vue'),
    meta: {
      title: '代理介绍'
    }
  },
  {
    path: '/showPdf1',
    name: 'ShowPdf1',
    component: () => import('@/views/portal/inviteFriends/showPdf1.vue'),
    meta: {
      title: '如何验证'
    }
  },
  // 玩法详情页
  {
    path: '/detailView',
    name: 'DetailView',
    component: () => import('@/views/portal/detailView/index.vue'),
    meta: {
      title: '详情页'
    }
  },
  // 投注记录页
  {
    path: '/orderRecord',
    name: 'OrderRecord',
    component: () => import('@/views/portal/orderRecord'),
    meta: {
      title: '投注记录'
    }
  },
  {
    path: '/orderRecordSummary',
    name: 'OrderRecord',
    component: () => import('@/views/portal/orderRecordSummary'),
    meta: {
      title: '投注记录汇总'
    }
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/views/portal/rules.vue'),
    meta: {
      title: '游戏规则'
    }
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('@/views/portal/recharge/index.vue'),
    meta: {
      title: '充值'
    }
  },
  {
    path: '/hwrecharge/',
    name: 'HWrecharge',
    component: () => import('@/views/portal/recharge/HWrecharge.vue'),
    meta: {
      title: '汇旺充值'
    }
  },
  {
    path: '/addWalletAress',
    name: 'AddWalletAress',
    component: () => import('@/views/portal/recharge/addWalletAress.vue'),
    meta: {
      title: '添加地址'
    }
  },
  {
    path: '/reflect',
    name: 'Reflect',
    component: () => import('@/views/portal/recharge/reflect.vue'),
    meta: {
      title: '提现'
    }
  },
  {
    path: '/rechargeRecord',
    name: 'RechargeRecord',
    component: () => import('@/views/portal/recharge/rechargeRecord.vue'),
    meta: {
      title: '充提记录'
    }
  },
  {
    path: '/rechargeTutorial',
    name: 'RechargeTutorial',
    component: () => import('@/views/portal/recharge/rechargeTutorial.vue'),
    meta: {
      title: '充值教程'
    }
  },
  {
    path: '/inviteFriends',
    name: 'InviteFriends',
    component: () => import('@/views/portal/inviteFriends/index.vue'),
    meta: {
      title: '邀请好友'
    }
  },
  {
    path: '/partner',
    name: 'Partner',
    component: () => import('@/views/portal/inviteFriends/partner.vue'),
    meta: {
      title: '代理',
      showTab: true
    }
  },
  {
    path: '/redbagRecord',
    name: 'RedbagRecord',
    component: () => import('@/views/portal/redbag/redbagRecord.vue'),
    meta: {
      title: '领取记录'
    }
  },
  {
    path: '/commisRecord',
    name: 'CommisRecord',
    component: () => import('@/views/portal/commisRecord.vue'),
    meta: {
      title: '佣金记录'
    }
  },
  {
    path: '/lowerRecord',
    name: 'LowerRecord',
    component: () => import('@/views/portal/lowerRecord.vue'),
    meta: {
      title: '下级流水记录'
    }
  },
  {
    path: '/redbag',
    name: 'Redbag',
    component: () => import('@/views/portal/redbag/index.vue'),
    meta: {
      title: '购买虚拟币'
    }
  },
  {
    path: '/pwdRedbag',
    name: 'PwdRedbag',
    component: () => import('@/views/portal/redbag/pwdRedbag.vue'),
    meta: {
      title: '口令红包'
    }
  },
  {
    path: '/verifyFairness',
    name: 'VerifyFairness',
    component: () => import('@/views/portal/verifyFairness.vue'),
    meta: {
      title: '验证公平'
    }
  },
  {
    path: '/performance_record',
    name: 'PerformanceRecord',
    component: () => import('@/views/portal/inviteFriends/PerformanceRecord.vue'),
    meta: {
      title: '代理/直属业绩'
    }
  },
  {
    path: '/softwareDownload',
    name: 'SoftwareDownload',
    component: () => import('@/views/portal/softwareDownload.vue'),
    meta: {
      title: '相关软件下载'
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/user/report.vue'),
    meta: {
      title: '输赢报表'
    }
  },
  {
    path: '/reportAgent',
    name: 'Report',
    component: () => import('@/views/user/reportAgent.vue'),
    meta: {
      title: '团队输赢报表'
    }
  },
  {
    path: '/agentDetail',
    name: 'AgentDetail',
    component: () => import('@/views/user/agentDetail.vue'),
    meta: {
      title: '团队输赢报表详情'
    }
  },
  {
    path: '/teamReport',
    name: 'TeamReport',
    component: () => import('@/views/user/teamReport.vue'),
    meta: {
      title: '团队流水报表'
    }
  },
  {
    path: '/transRecord',
    name: 'TransRecord',
    component: () => import('@/views/user/transRecord.vue'),
    meta: {
      title: '交易记录'
    }
  },
  {
    path: '/agent',
    name: 'Agent',
    component: () => import('@/views/user/agent.vue'),
    meta: {
      title: '代理合作'
    }
  },
  {
    path: '/buyBit',
    name: 'BuyBit',
    component: () => import('@/views/user/buyBit.vue'),
    meta: {
      title: '购买虚拟币'
    }
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('@/views/vip/index.vue'),
    meta: {
      title: 'vip',
      showTab: true
    }
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/activity/index.vue'),
    meta: {
      title: 'activity',
      showTab: true
    }
  },
  {
    path: '/actUser3TimesGive',
    name: 'ActUser3TimesGive',
    component: () => import('@/views/activity/actUser3TimesGive.vue'),
    meta: {
      title: '每天3阶段流水任务奖励'
    }
  },
  {
    path: '/actUserfirstPlaymons',
    name: 'ActivityDetail',
    component: () => import('@/views/activity/actUserfirstPlaymons.vue'),
    meta: {
      title: '新人流水送'
    }
  },
  {
    path: '/actUserfirstSupple',
    name: 'ActUserfirstSupple',
    component: () => import('@/views/activity/actUserfirstSupple.vue'),
    meta: {
      title: '新用户首充送'
    }
  },
  {
    path: '/monthka',
    name: 'monthka',
    component: () => import('@/views/activity/monthka.vue'),
    meta: {
      title: '限时月卡'
    }
  },
  {
    path: '/actSupplEeverytime',
    name: 'ActSupplEeverytime',
    component: () => import('@/views/activity/actSupplEeverytime.vue'),
    meta: {
      title: '笔笔充值送活动'
    }
  },
  {
    path: '/actThroughtDZ',
    name: 'ActThroughtDZ',
    component: () => import('@/views/activity/actThroughtDZ.vue'),
    meta: {
      title: '电子闯关活动'
    }
  },
  {
    path: '/actThroughtQP',
    name: 'ActThroughtQP',
    component: () => import('@/views/activity/actThroughtQP.vue'),
    meta: {
      title: '棋牌闯关活动'
    }
  },
  {
    path: '/actThroughtHX',
    name: 'ActThroughtHX',
    component: () => import('@/views/activity/actThroughtHX.vue'),
    meta: {
      title: '哈希闯关活动'
    }
  },
  {
    path: '/actThroughtSX',
    name: 'ActThroughtSX',
    component: () => import('@/views/activity/actThroughtSX.vue'),
    meta: {
      title: '真人视讯闯关活动'
    }
  },
  {
    path: '/actThroughtTY',
    name: 'ActThroughtTY',
    component: () => import('@/views/activity/actThroughtTY.vue'),
    meta: {
      title: '体育闯关活动'
    }
  },
  {
    path: '/safeCenter',
    name: 'SafeCenter',
    component: () => import('@/views/safe/safeCenter.vue'),
    meta: {
      title: '安全中心'
    }
  },
  {
    path: '/setAccount',
    name: 'SetAccount',
    component: () => import('@/views/safe/setAccount.vue'),
    meta: {
      title: '设置账号'
    }
  },
  {
    path: '/setMail',
    name: 'SetMail',
    component: () => import('@/views/safe/setMail.vue'),
    meta: {
      title: '设置邮箱'
    }
  },
  {
    path: '/editMail',
    name: 'EditMail',
    component: () => import('@/views/safe/editMail.vue'),
    meta: {
      title: '修改邮箱'
    }
  },
  {
    path: '/editLoginPsd',
    name: 'EditLoginPsd',
    component: () => import('@/views/safe/editLoginPsd.vue'),
    meta: {
      title: '修改登录密码'
    }
  },
  {
    path: '/editReflectPsd',
    name: 'EditReflectPsd',
    component: () => import('@/views/safe/editReflectPsd.vue'),
    meta: {
      title: '修改提现密码'
    }
  },
  {
    path: '/forgetLoginpwd',
    name: 'ForgetLoginpwd',
    component: () => import('@/views/safe/forgetLoginpwd.vue'),
    meta: {
      title: '忘记登录密码'
    }
  },
  {
    path: '/forgetDrawpwd',
    name: 'ForgetDrawpwd',
    component: () => import('@/views/safe/forgetDrawpwd.vue'),
    meta: {
      title: '忘记提现密码'
    }
  },
  {
    path: '/setReflectPsd',
    name: 'SetReflectPsd',
    component: () => import('@/views/safe/setReflectPsd.vue'),
    meta: {
      title: '提币地址管理'
    }
  },
  // {
  //   path: '/zcrw',
  //   name: 'Zcrw',
  //   component: () => import('@/views/activity/zcrw.vue'),
  //   meta: {
  //     title: '找茬任务'
  //   }
  // },
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login'),
    meta: {
      title: '登录'
    }
  },
  // 注册
  {
    path: '/registry',
    name: 'Registry',
    component: () => import('@/views/auth/registry'),
    meta: {
      title: '注册'
    }
  },
  // 我的
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user'),
    meta: {
      title: '我的',
      showTab: true
    }
  }
]

const router = new VueRouter({
  // 记录上个页面的滚动位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
