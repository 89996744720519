<template>
  <div class="menu-list-pop">
    <div class="user-tools">
      <van-cell to="/taskCenter">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_VIP特权" :width="24" :height="24" />
            <span class="custom-title">VIP特权</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/shareFriends">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_邀请好友" :width="24" :height="24" />
            <span class="custom-title">邀请好友</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/reflect">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_提现" :width="24" :height="24" />
            <span class="custom-title">提现</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/taskCenter">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_任务" :width="24" :height="24" />
            <span class="custom-title">任务</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/redbag">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_红包" :width="24" :height="24" />
            <span class="custom-title">红包</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/inviteFriends">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_我的收入" :width="24" :height="24" />
            <span class="custom-title">我的收入</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/orderRecordSummary" class="">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_投注记录" :width="24" :height="24" />
            <span class="custom-title">投注记录</span>
          </div>
        </template>
      </van-cell>
      <van-cell to="/agent" class="shadow-cell">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_代理" :width="24" :height="24" />
            <span class="custom-title">代理</span>
          </div>
        </template>
      </van-cell>
      <van-cell is-link @click="showLanguage">
        <template #title>
          <div>
            <svg-icon class="mine_icon" icon-class="icon_语言" :width="24" :height="24" />
            <span class="custom-title">{{ currentLanguage }}</span>
          </div>
        </template>
      </van-cell>
    </div>
  </div>
</template>
<script>
import { getServerUrl } from '@/api/user'
export default {
  data() {
    return {
      telgramurl: '',
      officialurl: '',
      onlineurl: '',
      whatsappurl: '',
      currentLanguage: ''
    }
  },
  async created() {
    const res = await getServerUrl()
    this.telgramurl = res.telgramurl
    this.officialurl = res.officialurl
    this.onlineurl = res.onlineurl
    this.whatsappurl = res.whatsappurl
    this.currentLanguage = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === 'en' ? 'English' : '中文'
  },
  methods: {
    openNew(url) {
      window.open(url)
    },
    showLanguage() {
      this.$bus.$emit('showLanguage', true)
    }
  }
}
</script>
<style lang="less" >
.menu-list-pop{
    // height: 100%;
    // padding-bottom: 660px;
    height: calc(100% - 292px);
    overflow: auto;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    margin-bottom:112px;
    // width: 100vw;
    // padding: 0 30px;
    // padding-bottom: 50px;
    // margin-top: 180px;
    // background: url('~@/assets/portal/菜单背景卡片.png') repeat-y top;
    // background-size: 100% 100%;
    background:  rgba(7,8,36,1);
    background-image: radial-gradient(circle at 48% 0%, rgba(107,122,255,0.20) 0%, rgba(99,114,252,0.08) 21%, rgba(94,109,250,0.00) 47%), radial-gradient(circle at 29% 0%, rgba(227,80,251,0.16) 0%, rgba(214,104,250,0.05) 37%, rgba(209,114,249,0.00) 68%);
    box-shadow: inset 0 2px 2px 0 rgba(255,255,255,0.20);
    border-radius: 40px 40px 0px 0px;
    // .van-icon:before{
    //   display: none ;
    // }
    .user-tools {
      width:calc(100vw - 60px);
      margin: 0 30px;
      margin-bottom: -120px;
      box-shadow: var(--user-tools-shadow); // 这里使用 css 函数，是为了避免旧版本 vue-cli 依赖的 css 压缩工具（mini-css-extract-plugin）导致的 bug
      border-radius: 24px;
      overflow: hidden;
      // padding: 28px 66px 28px 32px;
      // background: #202a41;
      border-radius: 30px;
        .shadow-cell{
          border-bottom: 1px solid rgba(255,255,255,0.20);
          // box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.12);
        }
        .van-cell{
          position: relative;
          display: -webkit-box;
          display: -webkit-flex;
          display: flex;
          box-sizing: border-box;
          width: 100%;
          overflow: hidden;
          color: #fff;
          font-size: 28px;
          font-weight: 400;
          background:none;
          padding: 0;
          height: 96px;
          line-height: 96px;
          // box-shadow: inset 0 -1px 0 0 rgba(255,255,255,0.12);
        }
        .van-cell::after {
            border-bottom: 0 solid #ebedf0;
        }
        .van-cell__right-icon {
          line-height: 96px;
        }
        .mine_icon{
          width: 48px;height:48px;
          border-radius: 50%;
          position: relative;
          top: 12px;
          margin-right: 20px;
        }
    }
    // background: #202a41;
    // background: url(~@/assets/portal/customerService/bg_弹窗.png) no-repeat 50%;
    // background-size: 100% 100%;
    // border-radius: 28px;
    .close-icon{
        color: #fff;
        font-size: 48px;
        position: absolute;
        // top: 24px;
        // right: 24px;
        bottom: -60px;
        left: 250px;
        z-index: 9;
    }
    .bg-icon {
        position: absolute;
        top: 0;
        right: 20px;
        width: 680px;
        height: 560px;
        background: url(~@/assets/portal/customerService/customer_service_bg.png) no-repeat 50%;
        background-size: 100% 100%;
        z-index: 2;
    }
    .icon {
        height: 360px;
    }
    .list {
        position: relative;
        width: 450px;
        z-index: 99;
        top: 500px;
        margin: auto;
        .item {
            margin: 0 auto;
            margin-bottom: 10px;
            // padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 400px;
            height: 60px;
            border-bottom: 1px solid rgba(255,255,255,0.12);
            .left {
                display: flex;
                align-items: center;
                font-family: PingFangSC-Medium;
                font-size: 28px;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;
                .icon1 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/在线客服.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon2 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/Telegram.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon3 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/官方频道.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
                .icon4 {
                    margin-right: 20px;
                    width: 40px;
                    height: 42px;
                    background: url(~@/assets/portal/customerService/WhatsAPP.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
            }
            .right {
                height: 48px;
                width: 160px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-image: linear-gradient(-41deg, #2653E6 0%, #ED62FC 100%);
                border-radius: 24px;
                font-family: PingFangSC-Medium;
                font-size: 28px;
                color: #FFFFFF;
                letter-spacing: 0;
                font-weight: 500;
                .btn_jinru {
                    margin-left: 20px;
                    width: 14px;
                    height: 20px;
                    background: url(~@/assets/portal/customerService/btn_jinru2.png) no-repeat 50%;
                    background-size: 100% 100%;
                }
            }
        }
        .item:last-child{
          border-bottom: none;
        }
    }
}
.van-popup {
    overflow-y: initial;
    background-color: transparent;
}
</style>
