<!--
 * @Author: Xushijie 87086132@qq.com
 * @Date: 2024-03-26 23:20:38
 * @LastEditors: Xushijie 87086132@qq.com
 * @LastEditTime: 2024-03-31 13:08:15
 * @FilePath: \renren\src\components\SvgIcon\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <svg
    :class="svgClass"
    aria-hidden="true"
    :width="width ? width : size"
    :height="height ? height : size"
    v-on="$listeners"
  >
    <use :href="iconName" />
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '28px'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  /* vertical-align: -0.15em; */
  fill: currentColor;
  overflow: hidden;
}
</style>
