import request from '@/utils/request'
export function getTaskList(data) {
  return request({
    url: '/task/getTask',
    method: 'get',
    params: data
  })
}
export function tknextNoopen(data) {
  return request({
    url: '/tknextNoopen',
    method: 'get',
    params: data
  })
}
export function kaiAct(data) {
  return request({
    url: '/task/kaiAct',
    method: 'get',
    params: data
  })
}
export function lingAct(data) {
  return request({
    url: '/task/lingAct',
    method: 'get',
    params: data
  })
}

export function reportAgent(data) {
  return request({
    url: '/agent/getMonthData',
    method: 'get',
    params: data
  })
}
export function getOrder(data) {
  return request({
    url: '/huione/getOrder',
    method: 'get',
    params: data
  })
}
export function getRgHuione(data) {
  return request({
    url: '/huione/getRgHuione',
    method: 'get',
    params: data
  })
}
export function getRgBaiyi(data) {
  return request({
    url: '/huione/getRgBaiyi',
    method: 'get',
    params: data
  })
}
export function getHuiOneinfo(data) {
  return request({
    url: '/huione/getHuiOneinfo',
    method: 'get',
    params: data
  })
}
export function sendDraw(data) {
  return request({
    url: '/draw/sendDraw',
    method: 'get',
    params: data
  })
}
export function buyGamerecord(data) {
  return request({
    url: '/pay/buyGamerecord',
    method: 'get',
    params: data
  })
}
export function getAgentperformance(data) {
  return request({
    url: '/invite/getAgentperformance',
    method: 'get',
    params: data
  })
}
export function gotoGame(data) {
  return request({
    headers: { 'Cache-Control': 'no-cache,no-store,must-revalidate' },
    url: '/game/gotoGame',
    method: 'get',
    params: data
  })
}
export function getGametypeList(data) {
  return request({
    url: '/game/getGametypeList',
    method: 'get',
    params: data
  })
}
export function getGameplayList(data) {
  return request({
    url: '/game/getGameplayList',
    method: 'get',
    params: data
  })
}
export function winReport(data) {
  return request({
    url: '/pay/winReport',
    method: 'get',
    params: data
  })
}
export function teamWinReport(data) {
  return request({
    url: '/pay/teamwinReport',
    method: 'get',
    params: data
  })
}
export function toprecord(data) { // 充值记录
  return request({
    url: '/pay/toprecord',
    method: 'get',
    params: data
  })
}
export function drawrecord(data) { // 提现记录
  return request({
    url: '/pay/drawrecord',
    method: 'get',
    params: data
  })
}
export function changes(data) { // 账变
  return request({
    url: '/pay/changes',
    method: 'get',
    params: data
  })
}
export function dividend(data) { // 红利
  return request({
    url: '/pay/dividend',
    method: 'get',
    params: data
  })
}
export function packinfo(data) { // 红包
  return request({
    url: '/redpack/packinfo',
    method: 'get',
    params: data
  })
}
export function addredpack(data) { // 发红包
  return request({
    url: '/redpack/addredpack',
    method: 'get',
    params: data
  })
}
export function getrecord(data) { // 红包领取记录
  return request({
    url: '/redpack/getrecord',
    method: 'get',
    params: data
  })
}
export function shareother(data) { // 邀请好友 分享
  return request({
    url: '/invite/shareother',
    method: 'get',
    params: data
  })
}
export function agentdata(data) { // 我的佣金
  return request({
    url: '/invite/agentdata',
    method: 'get',
    params: data
  })
}
export function agentdata2(data) { // 我的收入
  return request({
    url: '/invite/agentdata2',
    method: 'get',
    params: data
  })
}
export function getAgentinfo(data) { // 佣金百分比
  return request({
    url: '/user/getAgentinfo',
    method: 'get',
    params: data
  })
}
export function getAgentInvite(data) { // 领取佣金
  return request({
    url: '/invite/getAgentInvite',
    method: 'get',
    params: data
  })
}
export function record(data) { // 佣金领取记录
  return request({
    url: '/invite/record',
    method: 'get',
    params: data
  })
}
export function agentQuery(data) { // 佣金领取记录
  return request({
    url: '/invite/agentQuery',
    method: 'get',
    params: data
  })
}
export function getChildMonthData(data) { // 代理下级团队报表接口
  return request({
    url: '/agent/getChildMonthData',
    method: 'get',
    params: data
  }).then(res => {
    res.dataList = res.datalist.map(item => {
      return [{
        label: '名称', value: item.uname }, {
        label: '游戏输赢', value: item.gamewin }, {
        label: '充值', value: item.allcz }, {
        label: '提现', value: item.alltx }, {
        label: '余额', value: item.mons }, {
        label: '未领取返点', value: item.backmons }, {
        label: '飞机号', value: item.ubotname }
      ]
    })
    return res
  })
}
export function getSuppleUrl(data) { // 充值地址
  return request({
    url: '/user/getSuppleUrl',
    method: 'get',
    params: data
  })
}
export function drawinfos(data) { // 提现地址
  return request({
    url: '/draw/drawinfos',
    method: 'get',
    params: data
  })
}
export function addWebDrawurl(data) { // 添加提现地址
  return request({
    url: '/draw/addWebDrawurl',
    method: 'get',
    params: data
  })
}
export function delWebDrawurl(data) { // 删除提现地址
  return request({
    url: '/draw/delWebDrawurl',
    method: 'get',
    params: data
  })
}
export function webDrawIndex(data) { // 网页提现首页信息
  return request({
    url: '/draw/webDrawIndex',
    method: 'get',
    params: data
  })
}
export function getWebDrawurl(data) { // 提现地址查询
  return request({
    url: '/draw/getWebDrawurl',
    method: 'get',
    params: data
  })
}
export function doWebWithdraw(data) { // 网页提现接口
  return request({
    url: '/draw/doWebWithdraw',
    method: 'get',
    params: data
  })
}
export function getServerUrl(data) { // 客服地址
  return request({
    url: '/getServerUrl',
    method: 'get',
    params: data
  })
}
export function getNotice(data) { // 通知弹框
  return request({
    url: '/getNotice',
    method: 'get',
    params: data
  })
}
export function shareown(data) { // 通知弹框
  return request({
    url: '/invite/shareown',
    method: 'get',
    params: data
  })
}
export function shareinfo(data) { // 通知弹框
  return request({
    url: '/redpack/shareinfo',
    method: 'get',
    params: data
  })
}
export function getPackMons(data) { // 通知弹框
  return request({
    url: '/redpack/getPackMons',
    method: 'get',
    params: data
  })
}
export function buywater(data) { // 活动详情任务
  return request({
    url: '/activity/buywater',
    method: 'get',
    params: data
  })
}
// export function getInfo() {
//   return request({
//     url: '/user/info',
//     method: 'get'
//   })
// }
export function getInfo() {
  return request({
    url: '/user/getUserBalance',
    method: 'get'
  })
}
export function getUInfoByToken(data) {
  return request({
    url: '/getUInfoByToken',
    method: 'get',
    params: data
  })
}
export function profile(data) {
  return request({
    url: '/user/profile',
    method: 'post',
    data
  })
}

export function login(params) {
  return request({
    url: '/user/login',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

export function setRegistry(params) {
  return request({
    url: '/user/adduser',
    method: 'get',
    params
  })
}

export function getUcodeByhbid(params) { // 根据红包id获取邀请码
  return request({
    url: '/getucodeByhbid',
    method: 'get',
    params
  })
}

export function updatePassword(data) {
  return request({
    url: '/user/updatePassword',
    method: 'post',
    data
  })
}

export function uploadAvatar(data) {
  return request.formDataPost('/user/uploadAvatar', data)
}

export function getMailCode(data) {
  return request({
    url: '/user/sendEmailCode',
    method: 'post',
    data,
    timeout: 20000
  })
}

export function getLogo(data) {
  return request({
    url: '/getLogo',
    method: 'get',
    data
  })
}
