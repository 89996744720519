
<template>
  <div class="registry">
    <div class="content">
      <div class="header__title">{{ $t('register.zcym01') }}</div>
      <div class="notice-line">
        <div>{{ $t('register.zcym02') }}</div>
        <div class="main" @click="toLogin">{{ $t('register.zcym03') }}</div>
      </div>
      <van-form class="form" @submit="onSubmit">
        <van-field
          v-model="form.loginname"
          autocomplete="off"
          clearable
          name="账号"
          label=""
          :placeholder="$t('register.zcym04')"
          :rules="[
            {
              required: true,
            }
          ]"
        />
        <van-field
          v-model="form.loginpwd"
          autocomplete="off"
          type="password"
          center
          clearable
          name="密码"
          label=""
          :placeholder="$t('register.zcym05')"
          :rules="[
            { required: true,
            },{pattern:/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$/
               ,message:$t('register.zcym11'),trigger:'onBlur'}
          ]"
        />
        <van-field
          v-model="form.password2"
          autocomplete="off"
          type="password"
          center
          clearable
          name="密码"
          label=""
          :placeholder="$t('register.zcym06')"
          :rules="[
            { required: true
            },{pattern:/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$/
               ,message:$t('register.zcym11'),trigger:'onBlur'}
          ]"
        />
        <van-field
          v-model="form.yqcode"
          autocomplete="off"
          type="text"
          center
          clearable
          name="邀请码"
          label=""
          :placeholder="$t('register.zcym07')"
          :disabled="hasCode"
          :rules="[
            { required: false
            }
          ]"
        />

        <div style="margin-top: 2vh;">
          <van-button
            style="height:48px;font-size:20px;;border: none;
            background-image: linear-gradient(0deg, #6024FF 1%, #B661FF 100%);
            box-shadow: inset 0 -2px 4px 1px rgba(255,255,255,0.30);
            border-radius: 24px;"
            block
            :loading="loading"
            type="info"
            :loading-text="$t('register.zcym10')"
            native-type="submit"
          >{{ $t('register.zcym08') }}</van-button>
        </div>
      </van-form>
      <div class="notice-line">
        <div class="main" style="margin: auto;" @click="$emit('showService')">{{ $t('register.zcym09') }}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { setRegistry, getUcodeByhbid } from '@/api/user'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        loginname: undefined,
        loginpwd: undefined,
        yqcode: undefined
      },
      loading: false,
      registryVisible: false,
      isRememberPassword: true,
      hasCode: false,
      pattern: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9]{6,20}$'
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = (route.query && route.query.redirect) || process.env.VUE_APP_BASE_PATH
      },
      immediate: true
    }
  },
  mounted() {
    if (this.$route.query.hbid) {
      getUcodeByhbid({ hbid: this.$route.query.hbid }).then((res) => {
        this.form.yqcode = res.ucode
        this.hasCode = true
      })
    } else if (this.$route.query.upcode) {
      this.form.yqcode = this.$route.query.upcode
      this.hasCode = true
    } else {
      this.form.yqcode = ''
      this.hasCode = false
    }
  },
  methods: {
    toLogin() {
      // this.$store
      //   .dispatch('user/login', this.form)
      //   .then((res) => {
      //     this.$toast('注册登录成功')
      //     this.$emit('closeLogin')
      //   })
      this.$emit('toLogin', 1)
      // this.$router.push({
      //   path
      // })
    },
    // 密码校验
    checkPassword(password) {
      const reg = /^(\w){6,20}$/
      if (reg.test(password)) {
        return true
      }
      return false
    },
    // 获取图形验证码
    getVerifyCode() {

    },
    // 提交
    onSubmit() {
      const { loginpwd, password2 } = this.form
      if (loginpwd !== password2) {
        this.$toast.fail(this.$t('register.zcym12'))
        return
      }
      this.loading = true
      setRegistry(this.form)
        .then((res) => {
          // this.$notify({
          //   type: 'success',
          //   message: '注册成功，请登录',
          //   duration: 2000,
          //   onOpened: () => {
          //     this.$router.back()
          //   }
          // })
          // this.$toast('注册成功，请登录')
          this.toLogin()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">

.registry {
  background-color: rgba(32,42,67,1);
  border-radius: 30px;
  // height: 814px;
  .content{
    border-radius: 30px;
    background-image: url(~@/assets/portal/auth/bg_login.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 40px;
    .header__title {
      text-align: left;
      color: #fff;
      font-size: 40px;
      font-family: PingFangSC-Semibold;
      font-style: normal;
      font-weight: 600;
    }
    .notice-line{
      display: flex;
      color: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 400;
      font-family: PingFangSC-Regular;
      .main{
        color:  #00B7FF;
      }
    }
    .other{
      font-size: 3vw;
      color: #999;
      text-align: center;
      margin: 2.4vh 0 2vh 0;
    }
  }
  .form {
    // width: 70vw;
    // background: red;
    // padding: 24px;
    margin-top: 2.4vh;
  }
  .van-form .van-button__text{
    font-size: 30px !important;
  }
  //  .van-icon{
  //   margin-right: 5vw;
  // }
  // .van-field__error-message{
  //   text-align: center;
  // }
}
</style>
