
<template>
  <div class="login-container">
    <div class="content">
      <div class="header__title">{{ $t('login.dlym01') }}</div>
      <div class="notice-line">
        <div>{{ $t('login.dlym02') }}</div>
        <div class="main" @click="toRegistry">{{ $t('login.dlym03') }}</div>
      </div>
      <van-form class="form" @submit="onSubmit">
        <van-field
          v-model="form.loginname"
          autocomplete="off"
          clearable
          name="账号"
          label=""
          :placeholder="$t('login.dlym04')"
          :rules="[
            {
              required: true,
            },
          ]"
        >
          <!-- <template #left-icon>
            <van-icon style="margin:auto;line-height:40px;padding:0px 10px" size="20" color="rgba(121,135,164,1)" name="contact" />
          </template> -->
        </van-field>
        <van-field
          v-model="form.loginpwd"
          autocomplete="off"
          type="password"
          center
          clearable
          name="密码"
          label=""
          :placeholder="$t('login.dlym05')"
          :rules="[
            { required: true,
            }
          ]"
        >
          <!-- <template #left-icon>
            <van-icon style="margin:auto;line-height:40px;padding:0px 10px" size="20" color="rgba(121,135,164,1)" name="lock" />
          </template> -->
        </van-field>
        <div class="notice-line">
          <van-checkbox v-model="isRememberPassword" checked-color="#00B7FF" icon-size="16px" shape="square">{{ $t('login.dlym06') }}</van-checkbox> <div class="forget" @click="goMore">{{ $t('login.dlym07') }}</div>
        </div>
        <div style="margin-top: 2vh;">
          <van-button
            style="height:48px;font-size:20px;border: none;
            background-image: linear-gradient(0deg, #6024FF 1%, #B661FF 100%);
            box-shadow: inset 0 -2px 4px 1px rgba(255,255,255,0.30);
            border-radius: 24px;"
            block
            :loading="loading"
            type="info"
            :loading-text="$t('login.dlym09')"
            native-type="submit"
          >{{ $t('login.dlym08') }}</van-button>
        </div>
      </van-form>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      form: {
        loginname: undefined,
        loginpwd: undefined
      },
      loading: false,
      registryVisible: false,
      isRememberPassword: true
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = (route.query && route.query.redirect) || process.env.VUE_APP_BASE_PATH
      },
      immediate: true
    }
  },
  methods: {
    goMore() {
      this.$router.push({ path: '/forgetLoginpwd' })
    },
    toRegistry() {
      // this.$emit('changeDlg', 2, '48.5vh')
      this.$emit('changeDlg', 2)
    },
    toLoginByMobile() {
      this.$emit('changeDlg', 3)
    },
    toLoginByEmail() {
      this.$emit('changeDlg', 4)
    },
    to(path) {
      this.$router.push({
        path
      })
    },
    // 校检手机号
    checkPhone(num) {
      return true
      // if (num === 123456789) return true
      // const reg = /^[1][3,4,5,7,8][0-9]{9}$/
      // if (reg.test(num)) {
      //   return true
      // }
      // return false
    },
    // 提交
    // onSubmit(){

    // },
    onSubmit() {
      this.loading = true
      console.log(process.env.VUE_APP_BASE_PATH)
      this.$store
        .dispatch('user/login', this.form)
        .then((res) => {
          // this.$emit('getUserInfo', res)
          // this.$store.dispatch('address/getList')
          // this.$notify({
          //   type: 'success',
          //   message: '登录成功',
          //   duration: 1000,
          //   onOpened: () => {
          //     location.href = this.redirect
          //   },
          //   onClose: () => {
          //     location.href = this.redirect

          //   }
          // })
          this.$toast('登录成功')
          this.$emit('closeLogin')
          if (this.$route.query.hbid) {
            // this.$router.push({ path: '/home', params: { suid: res.suid, hbid: this.$route.query.hbid }})
            // window.location.reload()
          } else {
            // this.$router.push({ path: '/home', params: { suid: res.suid }})
            // window.open(window.location.origin + '/#/home?suid=' + res.suid, '_self')
            // window.location.reload()
          }
        })
        .catch((e) => {
          this.$toast.fail(e.message)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">

.login-container {
  background-color: rgba(32,42,67,1);
  border-radius: 30px;
  // height: 814px;
  .content{
    border-radius: 30px;
    // height: 500px;
    // background-image: linear-gradient(244deg, #001ECF 0%, #C91EFB 95%);
    background-image: url(~@/assets/portal/auth/bg_login.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 40px;
    .header__title {
      text-align: left;
      color: #fff;
      font-size: 40px;
      font-family: PingFangSC-Semibold;
      font-style: normal;
      font-weight: 600;
    }
    .notice-line{
      display: flex;
      color: #fff;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 28px;
      font-weight: 400;
      font-family: PingFangSC-Regular;
      .main{
        color:  #00B7FF;
      }
      .forget{
        color:  #00B7FF;
        margin-left: auto;
        // border-bottom: 1px solid
            // border-bottom-color: #283249;
            border-bottom-style: solid;
            border-bottom-width: 2px;
      }
    }
    .other{
      font-size: 3vw;
      color: #999;
      text-align: center;
      margin: 2.4vh 0 2vh 0;
    }
  }
  .form {
    // width: 70vw;
    // background: red;
    // padding: 24px;
    margin-top: 2.4vh;
  }
}

</style>
